<template>
  <div class="bg-f">
      <!--公共头部-->
      <Header></Header>
      <!-- 用户中心头部 -->
      <user-header />
      <!-- 内容 -->
      <div class="main-content">
          <div class="container">
              <div class="accout-content clearfix" style="display:flex">
                  <!-- 左边 -->
                  <user-left mark="exhibit"  :userId="userId"/>
                  <!-- 右边 -->
                  <a-card :bordered="false" class="card-right">
                      <div class="first">
                          <span><i>*</i>{{ $t('user.product.name-zh') }}:</span>
                          <input type="text" v-model="list.productName">
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.name-en') }}:</span>
                          <input type="text" v-model="list.productNameEn">
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.category-one') }}:</span>
                          <a-select :default-value="categoryId1" style="width: 310px" @change="handleChange">
                              <a-select-option :value="item.categoryId" v-for="(item,index) in catelist1" :key="index">
                                  {{item.categoryName}}
                              </a-select-option>
                          </a-select>       
                      </div>
                      <div class="second" v-if="categoryId1!=0">
                          <span><i>*</i>{{ $t('user.product.category-two') }}:</span>
                          <a-select :default-value="categoryId2" style="width: 310px" @change="handleChange2">
                             <a-select-option :value="item.categoryId" v-for="(item,index) in catelist2" :key="index">
                                  {{item.categoryName}}
                              </a-select-option>
                          </a-select>       
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.desc-zh') }}:</span>
                          <editor :value="list.productDesc" @change="val=>list.productDesc=val"></editor>
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.desc-en') }}:</span>
                          <editor :value="list.productDescEn" @change="val=>list.productDescEn=val"></editor>
                      </div>
                      <div class="second">
                          <span>{{ $t('user.product.min-price1') }}:</span>
                          <input type="text" v-model="list.minPrice">
                      </div>
                      <div class="second">
                          <span>{{ $t('user.product.max-price1') }}:</span>
                          <input type="text" v-model="list.maxPrice">
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.min-order') }}:</span>
                          <input type="text" v-model="list.minOrder">
                      </div>
                      <div class="second">
                          <span>{{ $t('user.keywords-zh') }}:</span>
                          <input type="text" v-model="list.keywords">
                          &nbsp;{{ $t('user.keywords-more') }}
                      </div>
                      <div class="second">
                          <span>{{ $t('user.keywords-en') }}:</span>
                          <input type="text" v-model="list.keywordsEn">
                          &nbsp;{{ $t('user.keywords-more') }}
                      </div>
                      <div class="second">
                          <span><i>*</i>{{ $t('user.product.pay-method') }}:</span>
                          <input type="text" v-model="list.payMethod">
                      </div>
                      <div class="second">
                          <span style="float:left"><i>*</i>{{ $t('user.product.img') }}:</span>
                          <a-upload
                              name='file'
                              list-type="picture-card"
                              :show-upload-list="false"
                              :customRequest="uploadImageFile"
                              :data="{ field: 'thumb' }"
                          >
                              <a-button :loading="thumbUploadStatus"> <a-icon type="upload" /> {{ $t('user.video.img-upload') }} </a-button>
                          </a-upload>
                          <img :src="list.thumb" alt="" v-if="list.thumb" width="100px" height="100px" style="margin-left:30px">
                      </div>
                      <div class="second">
                          <span style="float:left"><i>*</i>{{ $t('user.product.imgs') }}:</span>
                          <a-upload
                              name='file'
                              list-type="picture-card"
                              :show-upload-list="false"
                              :customRequest="uploadImageFile"
                              :data="{ field: 'images' }"
                          >
                              <a-button :loading="imagesUploadStatus"> <a-icon type="upload" /> {{ $t('user.product.imgs-upload') }} </a-button>
                          </a-upload>
                          <div style="display:flex" v-if="list.images">
                              <div v-for="(item,index) in list.images" :key="index" style="display:inline-block;width:180px;height:180px;position:relative;overflow:hidden;margin-left:10px">
                                  <img :src="item" alt="" style="margin-left:10px">
                                  <a-icon type="close" style="position:absolute;top:0;right:0" @click="viedoRemove(index)"/>
                              </div>
                          </div>
                      </div>
                       <div class="second">
                        <span style="float:left">{{ $t('user.product.videos') }}:</span>
                         <a-upload
                              name='file'
                              list-type="picture-card"
                              :show-upload-list="false"
                              :customRequest="uploadVideoFile"
                              :data="{ field: 'videoUrls' }"
                          >
                              <a-button :loading="videoUrlsUploadStatus"> <a-icon type="upload" /> {{ $t('user.video-upload') }} </a-button>
                          </a-upload>
                          <div style="width:300px;height:180px;position:relative" v-show="list.medias" v-for="(item,index) in list.medias" :key="index">
                            <video :src="item.url" controls="controls" class="video"></video>
                            <a-icon type="close" style="position:absolute;top:0;right:0" @click="viedoRemove1(index)"/>
                          </div>
                      </div>
                      <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                  </a-card>
              </div>
          </div>
      </div>
      <!-- 公共尾部 -->
      <Footer></Footer>
  </div>
</template>

<style>
  .main-content .card-right{
      width: 100%;
  }
  .first,.second{
      text-align: left;
  }
  .second{
    display: flex;
  }
  .first>span,.second>span{
      font-size: 16px;
      width: 140px;
      display: inline-block;
  }
  .first>span i,.second>span i{
    color: red;
    margin-right: 5px;
  }
  input[type=text]{
    width: 300px;
    border: 1px solid #787878;
    margin-left: 10px;
  }
  .second{
      margin-top: 20px;
  }
  .submit{
      width: 200px;
      height: 40px;
      margin-top: 40px;
      border: 1px solid  #d9d9d9;
      line-height: 40px;
      background: #ff4d4f;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
  }
  .ant-select-selection--single{
      margin-left: 10px;
      border: 1px solid black;
  }
  .ant-upload.ant-upload-select-picture-card{
      margin-left: 10px;
  }
  .video{
    width: 100%;
    height: 100%;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
name: 'ExbitEdit',
components: {
  UserHeader,
  Footer,
  UserLeft,
  Editor,
  Header
},
data () {
  return {
      userId: this.$route.params.userId,
      id: this.$route.params.id,
      list: [],
      catelist1: [],
      catelist2: [],
      categoryId1: this.$route.params.categoryId1,
      categoryId2: this.$route.params.categoryId2,
      thumbUploadStatus: false,
      imagesUploadStatus: false,
      videoUrlsUploadStatus: false
  }
},
 mounted () {
  this.getProduct()
  this.getList()
  this.getList2()
},
 methods: {
  //产品详情
  getProduct() {
    this.$common.fetchList('/user/product/detail/'+this.id, {}).then(data => {
    if (data.code === 200) {
        const result = data.data
        this.list = result
     }
    }).catch(() => {
        this.$layer.alert(1)
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        
      })
    })
  },
  handleChange(value) {
     this.categoryId1 = value
     this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
      if (data.code === 200) {
        const result = data.data
        this.catelist2 = result
        this.categoryId2 = ''
      }
    }).catch(() => {
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
      })
    })
  },
  handleChange2(value) {
    this.categoryId2 = value
    console.log(this.categoryId2)
  },
  // 上传图片
  uploadImageFile (file) {
    console.log('file', file)
    const field = file.data.field
    this[field + 'UploadStatus'] = true // 按钮状态
    const formData = new FormData()
    formData.append('file', file.file)
    this.$common.uploadImageFile(formData).then(data => {
      console.log(data)
      if (data.code === 200) {
        const result = data.data
        if (field === 'images') {
          this.list.images.push(result.url)
        } else {
          this.list.thumb = result.url
        }
      } else {
        this.$message.error(data.msg)
      }
    }).catch(() => {
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
      })
    }).finally(() => {
      this[field + 'UploadStatus'] = false
    })
  },
  // 图片删除
  viedoRemove (index) {
    const len = this.list.images.length
    if (index <= len - 1) {
      this.list.images.splice(index, 1)
    }
  },
  //上传视频
  uploadVideoFile (file) {
    console.log('file', file)
    const field = file.data.field
    this[field + 'UploadStatus'] = true // 按钮状态
    const formData = new FormData()
    formData.append('file', file.file)
    this.$common.uploadVideoFile(formData).then(data => {
      console.log(data)
      if (data.code === 200) {
        const result = data.data
        this.list.medias.push({'url':result.url, 'thumb':result.thumb, 'duration': result.duration})
      } else {
        this.$message.error(data.msg)
      }
    }).catch(() => {
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
      })
    }).finally(() => {
      this[field + 'UploadStatus'] = false
    })
  },
   // 视频删除
  viedoRemove1 (index) {
    const len = this.list.medias.length
    if (index <= len - 1) {
      this.list.medias.splice(index, 1)
    }
  },
  // 获取行业列表
  getList () {
     this.$common.fetchList('/exhibition/category/lists?parentId=0', {}).then(data => {
      if (data.code === 200) {
        const result = data.data
        this.catelist1 = result
      }
    }).catch(() => {
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
      })
    })
  },
   // 获取行业列表
  getList2 () {
     this.$common.fetchList('/exhibition/category/lists?parentId='+this.categoryId1, {}).then(data => {
      if (data.code === 200) {
        const result = data.data
        this.catelist2 = result
      }
    }).catch(() => {
      this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
      })
    })
  },
  // 产品修改
  regsub() {
      // if (this.list.productName == '' || this.list.productNameEn == '' || this.categoryId1 == '' || this.categoryId2 == '' || this.list.productDesc == '' 
      //     || this.list.productDescEn == '' || this.list.minOrder == '' || this.list.payMethod == '' || this.list.thumb == '' || this.list.images == '') {
      //     this.$layer.alert("请正确填写信息");
      //     return 
      // }
       if (this.list.productName == '') {
        this.$layer.alert("中文产品名称不能为空");
        return 
      } else if (this.list.productNameEn == '') {
        this.$layer.alert("英文产品名称不能为空");
        return 
      } else if (this.categoryId1 == '') {
        this.$layer.alert("一级分类不能为空");
        return 
      } else if (this.categoryId2 == '') {
        this.$layer.alert("二级分类不能为空");
        return 
      } else if (this.list.productDesc == '') {
        this.$layer.alert("中文产品描述不能为空");
        return 
      } else if (this.list.productDescEn == '') {
        this.$layer.alert("英文产品描述不能为空");
        return 
      } else if (this.list.minOrder == '') {
        this.$layer.alert("最小订单数不能为空");
        return 
      } else if (this.list.payMethod == '') {
        this.$layer.alert("支付方式不能为空");
        return 
      } else if (this.list.thumb == '') {
        this.$layer.alert("产品封面图不能为空");
        return 
      } else if (this.list.images == '') {
        this.$layer.alert("产品图片集不能为空");
        return 
      }
      const params = {
          productName: this.list.productName,
          productNameEn: this.list.productNameEn,
          categoryId1: this.categoryId1,
          categoryId2: this.categoryId2,
          productDesc: this.list.productDesc,
          productDescEn: this.list.productDescEn,
          minPrice: this.list.minPrice,
          maxPrice: this.list.maxPrice,
          minOrder: this.list.minOrder,
          keywords: this.list.keywords,
          keywordsEn: this.list.keywordsEn,
          payMethod: this.list.payMethod,
          thumb: this.list.thumb,
          images: this.list.images,
          medias: this.list.medias,
      }
      this.$common.handlePost('/user/product/edit/'+this.id, params).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.$router.push({name: 'UsersExhibit', params: {userId: this.userId}})
      } else {
          this.$layer.alert(data.msg);
      }
    }).catch(() => {
      this.$notification.error({
      message: '错误',
      description: '请求信息失败，请重试'
      })
    })
  }
 }
}
</script>